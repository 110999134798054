import React from 'react';
import './home.scss';

export default function Home() {
  const logo = "https://technosolutionsmx.com/wp-content/uploads/2021/05/sh-1.png";
  return (
    <React.Fragment>
      <h2 className={'content-block'}>Bienvenido a Techno Solutions</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
         
        <img src={logo} style={{ width: 280, height: 120 }} alt="Logo" />
          
          <p>
            <span>Administración de empleados </span>
            
          </p>
             <ul>
            <li><a href={'/#/empleados'} rel={'noopener noreferrer'}>Empleados</a></li>
            <li><a href={'/#/asistencia'} rel={'noopener noreferrer'}>Asistencia</a></li>
            
          </ul>
        </div>
      </div>
    </React.Fragment>
)}
