import React from 'react';
import 'devextreme/data/odata/store';
import { useNavigate } from 'react-router-dom'
import DataGrid, {
  Column,
  Pager,
  Paging,
  Export,
  Lookup,
  FilterRow,
  SearchPanel,
  HeaderFilter,
  FilterPanel,
  FilterBuilderPopup,
  Editing,
  Popup,
  RequiredRule,
  Form
  
} from 'devextreme-react/data-grid';

import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import {empleados,PutData} from '../../api/crm'
import { Item } from 'devextreme-react/form';
// Our demo infrastructure requires us to use 'file-saver-es'.
// We recommend that you use the official 'file-saver' package in your applications.
import { exportDataGrid } from 'devextreme/excel_exporter';
import CustomStore from 'devextreme/data/custom_store';


export default function Task() {
  const navigate = useNavigate()
  const rowOnClick = function(e) {
    let keys = e.component.getSelectedRowKeys();  
    let index = keys.indexOf(e.key);  

    navigate('/empleado_asistencia',{ state: { id: e.key, data: e.data } });
    
  }
  return (
    <React.Fragment>
      
      <DataGrid
        className={'dx-card wide-card'}
        dataSource={dataSource}
        showBorders={false}
        focusedRowEnabled={true}
        defaultFocusedRowIndex={0}
        columnAutoWidth={true}
        columnHidingEnabled={true}
        onExporting = {onExporting}
        onRowClick={rowOnClick}
        defaultFilterValue={filterValue}
      >
        <Paging defaultPageSize={10} />
        <Pager showPageSizeSelector={true} showInfo={true} />
        <SearchPanel visible={true}></SearchPanel>
        <Export enabled={true}  allowExportSelectedData={true} />
        <HeaderFilter visible={true} />
        <FilterPanel visible={true} />
        <FilterBuilderPopup position={filterBuilderPopupPosition} />
        <Editing
            mode="popup"
            allowUpdating={true}
            allowAdding={false}
            allowDeleting={false}>
              <Popup title="Emplado" showTitle={true} width={600} height={525} />
              <Form>
                <Item dataField="SYS_STATE"  >
                    <RequiredRule />
                  </Item>

                </Form>
        </Editing>
        <Column
          dataField={'codigo'}
          caption={'Código'}
          hidingPriority={3}
          allowFiltering={false}
        />
        <Column
          dataField={'nombre'}
          caption={'Nombre'}
          hidingPriority={3}
          allowFiltering={false}
        />
        <Column
          dataField={'apellidos'}
          caption={'Apellidos'}
          hidingPriority={3}
          allowFiltering={false}
        />

        <Column
          dataField={'phone'}
          caption={'Teléfono'}
          hidingPriority={3}
          allowFiltering={false}
        />

        <Column
          dataField={'email'}
          caption={'Email'}
          hidingPriority={3}
          allowFiltering={false}
        />

        <Column
          dataField={'estado'}
          caption={'Region'}
          hidingPriority={3}
        />

      <Column
          dataField={'cp'}
          caption={'CP'}
          hidingPriority={3}
        />
        
        <Column
          dataField={'SYS_CREATE_AT'}
          caption={'Fecha Inicial'}
          dataType={'date'}
          hidingPriority={3}
        />
        <Column
          dataField={'SYS_STATE'}
          caption={'Estatus del empleado'}
          hidingPriority={1}
        >
          <Lookup dataSource={states} valueExpr="ID" displayExpr="Name" />
          </Column>
      
      </DataGrid>
    </React.Fragment>
)}

const onExporting =  (e) => {
  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet('Main sheet');

  exportDataGrid({
    component: e.component,
    worksheet,
    autoFilterEnabled: true,
  }).then(() => {
    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DataGrid.xlsx');
    });
  });
  e.cancel = true;
}; 


const updateEmpleado = (key,values) => {
  PutData(`empleado/${key}`,values);
};

const filterValue = [['SYS_STATE', '=', 10]];

const dataSource = new CustomStore({
    key: 'empleado_id',
    load : () => empleados(),
    update : updateEmpleado,
  });
  
  const filterBuilderPopupPosition = {
    of: window,
    at: 'top',
    my: 'top',
    offset: { y: 10 },
  };

  
  const states = [{
    ID : 10,
    Name : "Activo"
  },
  {
    ID : 20,
    Name : "Inactivo"
  }]
  