import React , { useState } from 'react';
import 'devextreme/data/odata/store';

import {Scheduler,Editing } from 'devextreme-react/scheduler';
import {asistencia_empleado} from '../../api/crm'
import {sucursal, PostData, GetData,DeleteData} from '../../api/crm'
import { useLocation  } from 'react-router-dom'
import CustomStore from 'devextreme/data/custom_store';
import TabPanel, { Item } from "devextreme-react/tab-panel";
import './empleado_asistencia.scss';
import {  SelectBox, TextBox } from 'devextreme-react';
import notify from 'devextreme/ui/notify';


export default function Task() {
  const {state} = useLocation();
  const { id, data } = state;
  var sucursal_id = 0;
  if (data.sucursal_id > 0)
  sucursal_id = (data.sucursal_id);

  const [ sucursalId , setSucursalId] = useState(sucursal_id)

  const nameLabel = { 'aria-label': 'nombre' };
  const scheduler = React.createRef();
  
  var date_ini = new Date();
  
  //if (data.sucursal_id > 0)
    //  setSucursalId(data.sucursal_id)

 var dataSource = new CustomStore({
    key: 'time_clock_id',
    load : () => asistencia_empleado(id,date_ini),
    
  });
  
  const dataSourceSucursales = new CustomStore({
    key: 'sucursal_id',
    
    byKey: (key) => { return sucursal(key).then((response)=> {
      
      return response.data;});},
    load : () => { return sucursal().then((response)=> {
      
      return response.data;});}
    
  });
 
 
  const dataSourceDescansos =  new CustomStore({
    key: 'descanso_id',
    load : () => GetData(`empleados/descanso/${data.empleado_id}`),
    remove: (key) => DeleteData(`empleados/descanso/${key}`).then(() => notify("Se quito dia de descanso" )),
    insert : (values) => PostData(`empleados/descanso`,values).then(() => notify("Se agrego día de descanso" ))
  });

  

  const Field = function (data) {
    return (
      <div className="custom-item">
        <TextBox 
          
          defaultValue={data && data.cadena + "-"+data.nombre  }
          readOnly={true} />
          
      </div>
    );
  }

 

  const onValueChanged = function(e) {
    if (e.value.sucursal_id === undefined)
    return;

    const values = {
      "empleados" : [ {
        "empleado_id": data.empleado_id,
        "sucursal_id": e.value.sucursal_id
      }]
    };
    PostData(`empleados/sucursal`,values).then((response) =>{ 
      //state.data.sucursal_id = e.value.sucursal_id;
      //state.sucursal_id = state.data.sucursal_id
      
      setSucursalId(response.data.sucursal_id);
      notify("Se actualizo sucursal" );})
    
  }
  
  const onCellClick = function(e) {
      const item = {
        text : "Descanso",
        empleado_id : data.empleado_id,
        sucursal_id : data.sucursal_id,
        startDate : e.cellData.startDate,
        endDate : e.cellData.startDate,
        allDay : true
      };
      const result = scheduler.current.instance.filteredItems.find((element) => item.startDate.toDateString() === new Date(element.startDate).toDateString());
      
      if (result === undefined) {
        scheduler.current.instance.addAppointment(item);
      } else {
        scheduler.current.instance.deleteAppointment(result);
      }
        
  }

  
  return (
    <React.Fragment>
      <h5>Empleado: {data.codigo + "-" +data.nombre + " " + data.apellidos + " " + sucursalId }</h5>
      <TabPanel animationEnabled={true} swipeEnabled={true} >
      <Item title="Sucursal" icon="box">
        <div className="dx-fieldset">
          <div className="dx-field">
            <div className="dx-field-label">Sucursal</div>
            <div className="dx-field-value">
              
              <SelectBox dataSource={dataSourceSucursales}
               value={sucursalId}
               defaultValue={sucursalId}
               displayExpr="nombre"
               inputAttr={nameLabel}
               fieldRender={Field}
               itemRender={Field}
               placeholder="Seleccionar sucursal"
               onValueChanged={onValueChanged} />
            </div>
          </div>
          </div>
          <div >
          <Scheduler
          timeZone="America/Mexico_City"
          ref={scheduler}
        dataSource={dataSourceDescansos}
        views={['month']}
        defaultCurrentView="moth"
        defaultCurrentDate={date_ini}
        height={800}
        startDayHour={9} 
        onCellClick={onCellClick}
        >
           <Editing allowAdding={false} allowUpdating={false}/>
          </Scheduler> 
          </div>
        </Item>
      <Item title="Documentos" icon="rowfield">
      
      
         <span className='apptletico_content'>
         <div >
          <b>INE adverso</b><br/>
         <img src={ "https://techno.apptletico.com//images/"+data.url_ine_delantera} alt="url" height={350} width={280} />
         </div>
         </span>
         
         <span className='apptletico_content'>
          <div>
             <b>INE reverso</b><br/>
          <img src={ "https://techno.apptletico.com//images/"+data.url_ine_trasera} alt="url" height={350} width={280} />
          </div>
          </span>
         
         <span className='apptletico_content'>
         <b>RFC</b><br/>
         <img src={ "https://techno.apptletico.com//images/"+data.url_constanciafiscal} alt="url" height={350} width={280}/>
         </span>
         
         <span className='apptletico_content'>
         <b>CURP</b><br/>
         <img src={ "https://techno.apptletico.com//images/"+data.url_curp} alt="url" height={350} width={280} />
         </span>
         
         <span className='apptletico_content'>
         <b>Comprobante de Domicilio</b><br/>
         <img src={ "https://techno.apptletico.com//images/"+data.url_comprobante} alt="url" height={350} width={280}/>
         </span>
         
         
         
        
         
      </Item>
      <Item title="Scheduler" icon="event">
        
        <Scheduler
          timeZone="America/Mexico_City"
          

        dataSource={dataSource}
        views={['month']}
        defaultCurrentView="moth"
        defaultCurrentDate={date_ini}
        height={800}
        startDayHour={9} 
        >
            <Editing allowAdding={false} allowDeleting={false} allowUpdating={false}/>
          </Scheduler>
        </Item>
       
      </TabPanel>
      
    </React.Fragment>
)}




